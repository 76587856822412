<template lang="pug">
  v-container.fill-height
    v-row(align="center" justify="center")
      v-col.text-center(cols="12" sm="8" md="6")
        v-card.adock-card-with-header
          v-toolbar(
            color="primary"
            dark
            flat
          )
            v-toolbar-title
              h4 Récupération de l'accès au compte
          v-container.pa-6
            v-row
              v-col
                p Il est uniquement possible de récupérer l'accès à un compte A Dock (pas France Connect).
            v-form(
              v-model="isValid"
              ref="form"
              @submit="submit"
            )
              v-alert.adock-show-linebreaks(
                v-if="errorMessage"
                type="error"
                outlined
                text
              ) {{ errorMessage }}
              v-text-field(
                label="Adresse e-mail"
                v-model="inputEmail"
                :rules="emailRules"
                validate-on-blur
                :error-messages="fieldErrors.email"
              )
              v-row
                v-col(align="right")
                  v-btn(
                    color="primary"
                    @click="submit"
                    :disabled="isDisabled"
                    type="submit"
                  ) Récupérer l'accès
</template>

<script>
import api from "@/api";
import { emailRules } from "@/mixins";

export default {
  data() {
    return {
      inputEmail: this.email,
      isValid: false,
      errorMessage: "",
      fieldErrors: {},
    };
  },

  props: {
    email: {
      type: String,
      default: null,
      required: false,
    },
  },

  watch: {
    email(newEmail) {
      this.inputEmail = newEmail;
    },
  },

  created() {
    this.emailRules = emailRules;
  },

  computed: {
    isDisabled() {
      // Only for submit button
      const statuses = [];
      this.emailRules.forEach((rule) => statuses.push(rule(this.inputEmail)));
      return !statuses.every((v) => v === true);
    },
  },

  methods: {
    async submit(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        const response = await api.post(api.accountRecoverUrl, {
          email: this.inputEmail,
        });
        if (response.status === 200) {
          this.errorMessage = "";
          this.$router.push({
            name: "confirmations__waiting",
            params: {
              message: response.data.confirmation.message,
            },
          });
        } else {
          if (response.data.errors) {
            this.fieldErrors = response.data.errors;
          }
          if (response.data.message) {
            this.errorMessage = response.data.message;
          }
        }
      }
    },
  },
};
</script>
