import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height"},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","sm":"8","md":"6"}},[_c(VCard,{staticClass:"adock-card-with-header"},[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":""}},[_c(VToolbarTitle,[_c('h4',[_vm._v("Récupération de l'accès au compte")])])],1),_c(VContainer,{staticClass:"pa-6"},[_c(VRow,[_c(VCol,[_c('p',[_vm._v("Il est uniquement possible de récupérer l'accès à un compte A Dock (pas France Connect).")])])],1),_c(VForm,{ref:"form",on:{"submit":_vm.submit},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[(_vm.errorMessage)?_c(VAlert,{staticClass:"adock-show-linebreaks",attrs:{"type":"error","outlined":"","text":""}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_c(VTextField,{attrs:{"label":"Adresse e-mail","rules":_vm.emailRules,"validate-on-blur":"","error-messages":_vm.fieldErrors.email},model:{value:(_vm.inputEmail),callback:function ($$v) {_vm.inputEmail=$$v},expression:"inputEmail"}}),_c(VRow,[_c(VCol,{attrs:{"align":"right"}},[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.isDisabled,"type":"submit"},on:{"click":_vm.submit}},[_vm._v("Récupérer l'accès")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }